<template>
  <div class="app-container">
    <div v-if="user">
      <el-row :gutter="20">
        <el-col :span="6" :xs="24">
          <user-card :user="user" @changeAvatar="getUser(); notifyChange()" @deleteAvatar="deleteUserAvatar(), getUser()" />
        </el-col>
        <el-col :span="18" :xs="24">
          <el-card v-loading="loading">
            <el-tabs v-model="activeTab">
              <el-tab-pane label="Account" name="account">
                <account :user="user" />
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>

      </el-row>
    </div>
  </div>
</template>

<script>

import Resource from '@/api/resource';
import { mapGetters } from 'vuex';
import UserCard from './components/UserCard';
import Account from './components/Account';

const userResource = new Resource('users');

export default {
  name: 'Profile',
  components: { UserCard, Account },
  data() {
    return {
      componentKey: 0,
      activeTab: 'account',
    };
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'email',
      'roles',
      'id',
      'user',
    ]),
  },
  created() {
    this.getUser();
  },
  methods: {
    notifyChange() {
      console.log('This user profile to update', this.user);
      userResource.update(this.user.id, this.user).then(response => {
        this.$message({
          type: 'success',
          message: this.$t('user.updated'),
          duration: 5 * 1000,
        });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
      });
    },
    getUser() {
      this.user = {
        username: this.name,
        role: this.roles.join(' | '),
        email: this.email,
        avatar: this.avatar,
        id: this.id,
      };
      console.log('USER', this.user);
    },
    deleteUserAvatar(){
      const producteurRessource = new Resource('users');
      producteurRessource.destroy(this.user.id + '/delete_profile')
        .then(response => {
          this.$message({
            type: 'success',
            message: 'Supprimé avec succès',
          });
          this.getUser();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
  },
};
</script>

