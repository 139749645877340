<template>
  <el-form>
    <el-form-item label="userName">
      <el-input v-model.trim="userInfo.username" />
    </el-form-item>
    <el-form-item label="Email">
      <el-input v-model.trim="userInfo.email" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submit">Update</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Resource from '@/api/resource';
export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          username: '',
          email: '',
          id: '',
          role: '',
        };
      },
    },
  },
  data(){
    return {
      userInfo: this.user,
    };
  },
  methods: {
    submit() {
      const userRessource = new Resource('users');
      console.log('user to be updted', this.userInfo);
      userRessource.update(this.userInfo.id, this.userInfo)
        .then(response => {
          console.log('test: ', this.user);
          this.$message({
            message: this.$t('user.updated'),
            type: 'success',
            duration: 5 * 1000,
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {

        });
    },
  },
};
</script>
