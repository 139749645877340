<template>
  <el-card style="margin-bottom:20px;">
    <div slot="header" class="clearfix">
      <span>About me</span>
    </div>

    <div class="user-profile">
      <div class="box-center">
        <pan-thumb v-if="user.avatar !== null" :image="user.avatar !== null ? user.avatar : ''" :height="'100px'" :width="'100px'" :hoverable="false">
          <div>Hello</div>
          {{ user.role }}
        </pan-thumb>
        <div v-else>
          <el-avatar icon="el-icon-user-solid" />
        </div>
      </div>
      <div class="box-center">
        <div class="user-name text-center">{{ user.username }}</div>
        <div class="user-role text-center text-muted">{{ user.role | uppercaseFirst }}</div>
      </div>
    </div>
    <div class="user-follow">
      <el-button
        type="primary"
        icon="upload"
        style="width: 100%"
        @click="imagecropperShow = true"
      >
        {{ $t("user.changeAvatar") }}
      </el-button>
      <el-button
        v-if="user.avatar !== null"
        type="danger"
        style="width: 100%"
        @click="handleDeleteAvatar"
      >
        supprimer
      </el-button>
    </div>

    <image-cropper
      v-show="imagecropperShow"
      :key="imagecropperKey"
      :url="url"
      lang-type="fr"
      @close="close"
      @crop-upload-success="cropSuccess"
    />
  </el-card>
</template>

<script>
import PanThumb from '@/components/PanThumb';
import ImageCropper from '@/components/ImageCropper';

export default {
  components: { PanThumb, ImageCropper },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          username: '',
          email: '',
          avatar: '',
          role: '',
          id: '',
        };
      },
    },
  },
  data(){
    return {
      imagecropperShow: false,
      imagecropperKey: 0,
      image: '',
      userAvatar: '',
      userid: '',
      url: '',
    };
  },
  created() {
    this.url = 'users/' + this.user.id + '/changer_profile';
    console.log('the url', this.url);
  },
  methods: {
    cropSuccess() {
      this.imagecropperShow = false;
      this.imagecropperKey = this.imagecropperKey + 1;
      this.$emit('changeAvatar', true);
    },

    close() {
      this.imagecropperShow = false;
    },
    handleDeleteAvatar(){
      this.$emit('deleteAvatar', true);
    },

  },
};
</script>

<style lang="scss" scoped>
.box-center {
  margin: 0 auto;
  display: table;
}

.text-muted {
  color: #777;
}

.user-profile {
  .user-name {
    font-weight: bold;
  }

  .box-center {
    padding-top: 10px;
  }

  .user-role {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }

  .box-social {
    padding-top: 30px;

    .el-table {
      border-top: 1px solid #dfe6ec;
    }
  }

  .user-follow {
    padding-top: 20px;
  }
}

.user-bio {
  margin-top: 20px;
  color: #606266;

  span {
    padding-left: 4px;
  }

  .user-bio-section {
    font-size: 14px;
    padding: 15px 0;

    .user-bio-section-header {
      border-bottom: 1px solid #dfe6ec;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
  .user-follow {
    padding-top: 20px;
  }
}
</style>
